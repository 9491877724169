import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cell"]
  static values = {
    updateUrl: String
  }

  connect() {
    this.sourceElement = null;
    this.cellTargets.forEach((cell) => {
      cell.addEventListener('dragstart', this.dragstart.bind(this));
      cell.addEventListener('dragover', this.dragover.bind(this));
      cell.addEventListener('dragleave', this.dragleave.bind(this));
      cell.addEventListener('drop', this.drop.bind(this));
    });
  }

  dragstart(event) {
    this.sourceElement = event.target;
  }

  dragover(event) {
    event.preventDefault();

    let sourceCell = this.sourceElement.closest("[data-dragon-drop-target='cell']");
    let targetCell = event.target.closest("[data-dragon-drop-target='cell']");

    let sourcePosition = sourceCell.dataset.position;
    let targetPosition = targetCell.dataset.position;

    if (sourcePosition == targetPosition) {
      return;
    }

    targetCell.classList.add('opacity-50');
  }

  dragleave(event) {
    let targetCell = event.target.closest("[data-dragon-drop-target='cell']");
    targetCell.classList.remove('opacity-50');
  }

  drop(event) {
    let sourcePosition = this.sourceElement.closest("[data-dragon-drop-target='cell']").dataset.position;
    let targetPosition = event.target.closest("[data-dragon-drop-target='cell']").dataset.position;

    if (sourcePosition == targetPosition) {
      return;
    }

    event.preventDefault();
    this.dragleave(event);

    let sourceCell = this.sourceElement.closest("[data-dragon-drop-target='cell']");
    let targetCell = event.target.closest("[data-dragon-drop-target='cell']");

    this.swapElements(sourceCell, targetCell);
    this.swapPositions(sourceCell, targetCell);
  }

  swapElements(sourceCell, targetCell) {
    let sourceCellParent = sourceCell.parentElement;
    let targetCellParent = targetCell.parentElement;

    sourceCellParent.appendChild(targetCell);
    targetCellParent.appendChild(sourceCell);
  }

  swapPositions(sourceCell, targetCell) {
    let sourcePosition = sourceCell.dataset.position;
    let targetPosition = targetCell.dataset.position;

    // update data-position on cell
    sourceCell.dataset.position = targetPosition;
    targetCell.dataset.position = sourcePosition;

    // send update to server
    fetch(this.updateUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        source_position: sourcePosition,
        target_position: targetPosition
      })
    })
  }

}
