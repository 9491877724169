import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]
  static values = {
    disableButton: Boolean
  }

  connect() {
    this.button = this.element;

    // Set controller reference on element so can be accessed in other controllers
    this.button["spinnerButtonController"] = this;

    // Show spinner when button is clicked or form is submitted
    this.button.addEventListener("click", this.showSpinner.bind(this));

    // Hide spinner when Turbo stream response is rendered
    document.addEventListener("turbo:before-stream-render", this.hideSpinner.bind(this));
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");

    // disable the button after the request has gone through
    if (this.disableButtonValue) {
      setTimeout(() => {
        this.button.removeAttribute("href")
      }, 5)
    }
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }
}
